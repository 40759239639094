export const DATA_SCIENCE_PROCESS = [
    {
        title: 'ai-software-page.identifyProblem',
    },
    {
        title: 'ai-software-page.captureData',
    },
    {
        title: 'ai-software-page.knowledgeCollection',
    },
    {
        title: 'ai-software-page.autonomusAction',
    },
    {
        title: 'ai-software-page.value',
    },
];
