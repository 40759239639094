import React from 'react';
import { DATA_SCIENCE_PROCESS } from 'views/ai-software-development-company/data-science-process-section/constants';
import { ProcessSection } from 'modules/process-section';

export const DataScienceProcessSection = () => (
    <ProcessSection
        title="ai-software-page.theProcess"
        description="ai-software-page.checkOurStrategic"
        data={DATA_SCIENCE_PROCESS}
    />
);
