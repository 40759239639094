import React from 'react';
import { FormattedMessage } from 'react-intl';
import { CtaSectionExtended } from 'modules/cta-section-extended';

export const AiSoftwareCtaSectionExtended = () => {
    return (
        <CtaSectionExtended title="ai-software-page.cta-section.title">
            <p>
                <FormattedMessage id="ai-software-page.cta-section.headline" />
            </p>
            <ul>
                <li>
                    <FormattedMessage id="ai-software-page.cta-section.items.end-to-end" />
                </li>
                <li>
                    <FormattedMessage id="ai-software-page.cta-section.items.expertise" />
                </li>
                <li>
                    <FormattedMessage id="ai-software-page.cta-section.items.seamless-integration" />
                    <a href="https://themobilereality.com/blog/business/ai-agent-development">
                        <FormattedMessage id="ai-software-page.cta-section.items.ai-agents" />
                    </a>
                    <FormattedMessage id="ai-software-page.cta-section.items.to-automate" />
                </li>
                <li>
                    <FormattedMessage id="ai-software-page.cta-section.items.specialized-in-developing" />
                </li>
                <li>
                    <FormattedMessage id="ai-software-page.cta-section.items.advanced-capabilities" />
                </li>
            </ul>
        </CtaSectionExtended>
    );
};
