import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { FieldOfExpertise } from 'views/ai-software-development-company/fields-of-expertise-section/components/field-of-expertise';
import { FIELDS_OF_EXPERIENCE_DATA } from 'views/ai-software-development-company/fields-of-expertise-section/constants';
import { Container } from 'components/container';
import { HeaderSecond } from 'components/header-second';
import { Paragraph } from 'components/paragraph';
import { CONSTANTS } from 'constants/styles/constants';

const SHeaderSecond = styled(HeaderSecond)`
    text-align: center;
`;

const SParagraph = styled(Paragraph)`
    text-align: center;
    margin: 1.5rem auto 3.75rem;
    max-width: 52rem;
`;

const SWrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 4.75rem;
    ${CONSTANTS.MEDIA.max1024`
        grid-template-columns: 1fr 1fr;
    `}
    ${CONSTANTS.MEDIA.max768`
        grid-template-columns: 1fr;
    `}
`;

export const FieldsOfExpertiseSection = () => {
    const fieldsOfExpertiseToRender = FIELDS_OF_EXPERIENCE_DATA.map((item) => (
        <FieldOfExpertise key={item.title} item={item} />
    ));

    return (
        <Container>
            <SHeaderSecond>
                <FormattedMessage id="ai-software-page.ourFieldsOfExpertise" />
            </SHeaderSecond>
            <SParagraph>
                <FormattedMessage id="ai-software-page.chooseTheBestFields" />
            </SParagraph>
            <SWrapper>{fieldsOfExpertiseToRender}</SWrapper>
        </Container>
    );
};
