import React from 'react';
import { useIntl } from 'react-intl';
import styled, { css } from 'styled-components';
import { AiSoftwareCtaSectionExtended } from 'views/ai-software-development-company/ai-software-cta-section-extended';
import {
    LATEST_RELATED_ARTICLES_SETTINGS,
    useCaseStudiesData,
} from 'views/ai-software-development-company/constants';
import { DataScienceProcessSection } from 'views/ai-software-development-company/data-science-process-section';
import { FieldsOfExpertiseSection } from 'views/ai-software-development-company/fields-of-expertise-section';
import { MethodsSection } from 'views/ai-software-development-company/methods-section';
import { AnimatedDataScience } from 'components/animated-data-science';
import { PrefixLangLink } from 'components/prefix-lang-link';
import { gaTrackedEvents } from 'constants/ga-tracked-events';
import { PATHS } from 'constants/paths';
import { AIAssistantSection } from 'modules/ai-assistant-section';
import { FooterSection } from 'modules/footer-section';
import { MainSection } from 'modules/main-section';
import { PerksWithImageSection } from 'modules/perks-with-image-section';
import { ServicesCaseStudiesSection } from 'modules/services-case-studies-section';
import { array } from 'prop-types';

import { PERKS } from './constants';

const SPrefixLangLink = styled(PrefixLangLink)`
    color: inherit;
    text-decoration: underline;
    display: inline;
`;

const LINKS_CHUNKS = {
    FIRST: 'comprehensive AI development services',
    SECOND: 'integrate AI chatbot development',
};

const resolveTo = (chunk) => {
    if (chunk === LINKS_CHUNKS.FIRST) {
        return `${PATHS.EXPERTISES}`;
    }

    if (chunk === LINKS_CHUNKS.SECOND) {
        return `blog/business/ai-agent-development`;
    }

    return `${PATHS.CASE_STUDIES}/${PATHS.SALES_FORECASTING_SOFTWARE}`;
};

const Props = {
    faqData: array.isRequired,
};

export const AISoftwareDevelopmentCompany = ({ faqData }) => {
    const { formatMessage } = useIntl();

    return (
        <>
            <MainSection
                icon={<AnimatedDataScience />}
                iconWrapperCss={css`
                    max-width: 33.125rem;
                `}
                titleId="ai-software-page.dataScienceMachineLearning"
                paragraphs={[
                    formatMessage(
                        {
                            id: 'ai-software-page.unlockInsights',
                        },
                        {
                            link: (chunks) => (
                                <SPrefixLangLink to={resolveTo(chunks[0])}>
                                    {chunks}
                                </SPrefixLangLink>
                            ),
                        },
                    ),
                ]}
                estimateProjectAnalytics={
                    gaTrackedEvents.DATA_SCIENCE.CTA.ESTIMATE_PROJECT
                }
                intoCallAnalytics={gaTrackedEvents.DATA_SCIENCE.CTA.INTRO_CALL}
                isParagraphFormattedPreviously
            />
            <AIAssistantSection />
            <MethodsSection />
            <FieldsOfExpertiseSection />
            <AiSoftwareCtaSectionExtended />
            <DataScienceProcessSection />
            <PerksWithImageSection
                title="ai-software-page.view-data-science-solution-section.title"
                perks={PERKS}
            />
            <ServicesCaseStudiesSection
                caseStudiesData={useCaseStudiesData()}
                subtitleId="single-service-case-studies-section.subtitle.dataScience"
            />
            <FooterSection
                faqSection={{
                    title: faqData.title,
                    data: faqData.data,
                    parseRawAnswer: true,
                }}
                latestRelatedArticles={LATEST_RELATED_ARTICLES_SETTINGS}
            />
        </>
    );
};

AISoftwareDevelopmentCompany.propTypes = Props;
