import { CASE_STUDIES_TAG } from 'views/case-studies/constants';
import { BLOG_POST_TAGS } from 'constants/blog-post-tags';
import { PATHS } from 'constants/paths';
import { graphql, useStaticQuery } from 'gatsby';
import JavascriptSvg from 'svgs/blog/javascript.svg';

export const METHODS_TABS = [
    {
        id: `predictive-modeling`,
        title: `ai-software-page.predictiveModeling`,
        description: `ai-software-page.predictiveModelingDescription`,
    },
    {
        id: `clustering`,
        title: `ai-software-page.clustering`,
        description: `ai-software-page.clusteringDescription`,
    },
    {
        id: `association-analysis`,
        title: `ai-software-page.associationAnalysis`,
        description: `ai-software-page.associationAnalysisDescription`,
    },
    {
        id: `simulation-modeling`,
        title: `ai-software-page.simulationModeling`,
        description: `ai-software-page.simulationModelingDescription`,
    },
    {
        id: `text-mining`,
        title: `ai-software-page.textMining`,
        description: `ai-software-page.textMiningDescription`,
    },
    {
        id: `rpa`,
        title: `ai-software-page.rpa`,
        description: `ai-software-page.rpaDescription`,
    },
    {
        id: `customAI`,
        title: `ai-software-page.customAI`,
        description: `ai-software-page.customAIDescription`,
    },
    {
        id: `e2e`,
        title: `ai-software-page.e2e`,
        description: `ai-software-page.e2eDescription`,
    },
];

export const useCaseStudiesData = () => {
    const images = useStaticQuery(graphql`
        {
            pregnabit: file(
                relativePath: { eq: "case-studies/pregnabit-miniature.png" }
            ) {
                childImageSharp {
                    gatsbyImageData(
                        quality: 100
                        placeholder: NONE
                        layout: FULL_WIDTH
                        formats: [WEBP]
                    )
                }
            }
            salesPredictiveAlgorithm: file(
                relativePath: {
                    eq: "case-studies/sales-predictive-algorithm-miniature.png"
                }
            ) {
                childImageSharp {
                    gatsbyImageData(
                        quality: 100
                        placeholder: NONE
                        layout: FULL_WIDTH
                        formats: [WEBP]
                    )
                }
            }
            churn: file(
                relativePath: { eq: "case-studies/churn-miniature.png" }
            ) {
                childImageSharp {
                    gatsbyImageData(
                        quality: 100
                        placeholder: NONE
                        layout: FULL_WIDTH
                        formats: [WEBP]
                    )
                }
            }
        }
    `);

    return [
        {
            title: `case-studies-page.pregnabit`,
            field: `case-studies-page.field.healthcare`,
            tags: [
                CASE_STUDIES_TAG.DATA_SCIENCE,
                CASE_STUDIES_TAG.MACHINE_LEARNING,
            ],
            image: images.pregnabit.childImageSharp.gatsbyImageData,
            to: `${PATHS.CASE_STUDIES}/${PATHS.PREGNABIT}`,
        },
        {
            title: `case-studies-page.salesPredictiveAlgorithm`,
            field: `case-studies-page.field.dataAnalysis`,
            tags: [
                CASE_STUDIES_TAG.WEB_DEVELOPMENT,
                CASE_STUDIES_TAG.CLOUD_APPLICATION_DEVELOPMENT,
                CASE_STUDIES_TAG.DATA_SCIENCE,
                CASE_STUDIES_TAG.MACHINE_LEARNING,
                CASE_STUDIES_TAG.BUSINESS_INTELLIGENCE,
            ],
            image: images.salesPredictiveAlgorithm.childImageSharp
                .gatsbyImageData,
            to: `${PATHS.CASE_STUDIES}/${PATHS.SALES_FORECASTING_SOFTWARE}`,
        },
        {
            title: `case-studies-page.churn`,
            field: `case-studies-page.field.dataAnalysis`,
            tags: [
                CASE_STUDIES_TAG.WEB_DEVELOPMENT,
                CASE_STUDIES_TAG.DATA_ANALYSIS,
                CASE_STUDIES_TAG.DATA_SCIENCE,
            ],
            image: images.churn.childImageSharp.gatsbyImageData,
            to: `${PATHS.CASE_STUDIES}/${PATHS.CUSTOMER_CHURN_PREDICTION_SOFTWARE}`,
        },
    ];
};
const path = `ai-software-page.view-data-science-solution-section`;
export const PERKS = [
    {
        title: `${path}.dataScience.title`,
        text: `${path}.dataScience.desc`,
        details: [
            `${path}.dataScience.details.0`,
            `${path}.dataScience.details.1`,
            `${path}.dataScience.details.2`,
            `${path}.dataScience.details.3`,
            `${path}.dataScience.details.4`,
            `${path}.dataScience.details.5`,
            `${path}.dataScience.details.6`,
            `${path}.dataScience.details.7`,
            `${path}.dataScience.details.8`,
            `${path}.dataScience.details.9`,
            `${path}.dataScience.details.10`,
        ],
    },
    {
        title: `${path}.machineLearning.title`,
        text: `${path}.machineLearning.desc`,
        details: [
            `${path}.machineLearning.details.0`,
            `${path}.machineLearning.details.1`,
            `${path}.machineLearning.details.2`,
            `${path}.machineLearning.details.3`,
            `${path}.machineLearning.details.4`,
            `${path}.machineLearning.details.5`,
            `${path}.machineLearning.details.6`,
            `${path}.machineLearning.details.7`,
            `${path}.machineLearning.details.8`,
            `${path}.machineLearning.details.9`,
            `${path}.machineLearning.details.10`,
        ],
    },
    {
        title: `${path}.overallRecommendation.title`,
        text: `${path}.overallRecommendation.desc`,
    },
];

export const LATEST_RELATED_ARTICLES_SETTINGS = {
    hideTitle: true,
    showAllArticles: true,
    tags: [BLOG_POST_TAGS.DATA_SCIENCE_ML, BLOG_POST_TAGS.GEN_AI],
    button: {
        to: `${PATHS.BLOG}/${PATHS.JAVASCRIPT}`,
        label: 'ai-software-page.footer.button',
    },
    categoryBanner: {
        title: 'ai-software-page.footer.title',
        description: 'ai-software-page.footer.description',
        svg: JavascriptSvg,
    },
};
